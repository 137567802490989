import { REACT_APP_API_URL } from 'config';
import { formatDistanceToNow } from 'date-fns';
// import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { events, StatusEnum } from 'interfaces/common.interface';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { setCalenderView, setSourceView } from 'reduxStore/slices/calenderViewSlice';
import {
  currentInstituteSelector,
  processIdSelector,
  setSyncTime,
} from 'reduxStore/slices/instituteSlice';
import {
  setLoaderStatus,
  setReplaceDataStatus,
} from 'reduxStore/slices/loaderSlice';
// import { setToast } from 'reduxStore/slices/toastSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';
import openSocket from 'socket.io-client';

const SocketComponent = () => {
  const dispatch = useDispatch();
  const currentInstitute = useSelector(currentInstituteSelector);
  const process_id = useSelector(processIdSelector);
  const token = useSelector(getAuthToken);
  const navigate = useNavigate();
  const navigateRef = useRef<string>('');

  useEffect(() => {
    if (navigateRef.current) {
      navigate(navigateRef.current);
    }
  }, [navigateRef.current]);

  // const handleCompleteProcessOfScheduleGeneration = async (data: string) => {
  //   const { status, instituteId, processId } = JSON.parse(data);
  //   navigateRef.current = '';
  //   if (instituteId === currentInstitute?.value) {
  //     dispatch(setProcessId(processId));
  //     if (status === StatusEnum.PENDING) {
  //       dispatch(setLoaderState(true));
  //     } else if (status === StatusEnum.SUCCESS) {
  //       dispatch(setLoaderState(false));
  //       dispatch(setLoaderStatus(LoaderStatusEnum.COMPLETE));
  //       dispatch(setPrimaryStep(4));
  //       navigateRef.current = PRIVATE_NAVIGATION.calendar.path;
  //     } else {
  //       dispatch(setProcessId(''));
  //       dispatch(setLoaderState(false));
  //       dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
  //     }
  //   }
  // };

  // const handleSyncingStatus = async (
  //   data: string,
  //   obj?: { id: number; message: string }
  // ) => {
  //   const { status, instituteId } = JSON.parse(data);
  //   if (instituteId === currentInstitute?.value) {
  //     if (status === StatusEnum.SUCCESS) {
  //       if (obj?.id) {
  //         dispatch(setProgressStatus(obj.id));
  //       }
  //     } else if (status === StatusEnum.PENDING) {
  //       dispatch(setLoaderStatusMessage(obj?.message ?? 'Syncing Now...'));
  //       if (obj?.id) dispatch(setProgressStatus(obj.id));
  //     } else {
  //       dispatch(setProcessId(''));
  //       dispatch(setLoaderState(false));
  //       dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
  //       dispatch(
  //         setToast({
  //           id: new Date().getTime(),
  //           message: 'An error occurred please check your email for more details',
  //           type: 'error',
  //           variant: 'Error',
  //         })
  //       );
  //     }
  //   }
  // };

  // const handleScheduleGenerationSync = (data: string) => {
  //   const { status, instituteId } = JSON.parse(data);
  //   if (instituteId === currentInstitute?.value) {
  //     if (status === StatusEnum.PENDING) {
  //       dispatch(setLoaderStatus(true));
  //     } else if (status === StatusEnum.SUCCESS) {
  //       dispatch(setLoaderStatus(false));
  //     }
  //   }
  // };

  // const handleSessionTransfer = (data: string) => {
  //   const { status, availabilityId } = JSON.parse(data);
  //   navigateRef.current = '';
  //   dispatch(setAvailabilityId(availabilityId));
  //   if (status === StatusEnum.PENDING) {
  //     dispatch(setLoaderStatusMessage('Syncing Data...'));
  //     dispatch(setPrimaryStep(2));
  //     dispatch(setLoaderStatus(LoaderStatusEnum.IN_PROGRESS));
  //   } else if (status === StatusEnum.SUCCESS) {
  //     dispatch(setPrimaryStep(3));
  //     dispatch(setLoaderStatus(LoaderStatusEnum.SUCCESS));
  //   } else {
  //     dispatch(setProcessId(''));
  //     dispatch(setLoaderState(false));
  //     dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
  //   }
  // };

  // const handleCiplexCall = (data: string) => {
  //   const { status, instituteId, optimizerId } = JSON.parse(data);

  //   navigateRef.current = '';
  //   if (instituteId === currentInstitute?.value) {
  //     if (status === StatusEnum.PENDING) {
  //       dispatch(setLoaderStatus(LoaderStatusEnum.IN_PROGRESS));
  //       dispatch(setPrimaryStep(events.CIPLEX_CALL.id));
  //     } else if (status === StatusEnum.SUCCESS) {
  //       dispatch(setOptimizerId(optimizerId));
  //       dispatch(setCalenderView(false));
  //       dispatch(setSourceView(true));
  //       dispatch(setLoaderStatus(LoaderStatusEnum.SUCCESS));
  //       dispatch(setPrimaryStep(events.CIPLEX_CALL.id + 1));
  //       dispatch(setLoaderStatusMessage(events.CIPLEX_CALL.message));
  //     }
  //   }
  // };

  const handleData = (data: string) => {
    const { status, instituteId, processId, from_date, to_date } = JSON.parse(data);
    if (
      instituteId === currentInstitute?.value &&
      processId === process_id &&
      from_date + to_date === localStorage.getItem('filter_dates')
    ) {
      if (status === StatusEnum.PENDING) {
        dispatch(setLoaderStatus(true));
      } else if (status === StatusEnum.SUCCESS && processId === process_id) {
        dispatch(setLoaderStatus(false));
      }
    }
  };

  const handleEnableBothFlow = async (data: string) => {
    const { status, instituteId } = JSON.parse(data);
    if (instituteId === currentInstitute?.value) {
      if (status === StatusEnum.PENDING) {
        dispatch(setReplaceDataStatus(false));
      } else if (status === StatusEnum.SUCCESS) {
        dispatch(setReplaceDataStatus(true));
      }
    }
  };

  const handleUpdateLastSyncTime = async (data: string) => {
    const { status, instituteId, date } = JSON.parse(data);
    if (instituteId === currentInstitute?.value) {
      if (status === StatusEnum.SUCCESS) {
        dispatch(
          setSyncTime(
            formatDistanceToNow(new Date(date), {
              addSuffix: true,
            })
          )
        );
      }
    }
  };

  useEffect(() => {
    const socket = connectSocket();
    // socket?.on(events.SERVICE.name, (data) => {
    //   handleSyncingStatus(data, events.SERVICE);
    // });
    // socket?.on(events.SUBJECT.name, (data) =>
    //   handleSyncingStatus(data, events.SUBJECT)
    // );
    // socket?.on(events.TEACHER.name, (data) =>
    //   handleSyncingStatus(data, events.TEACHER)
    // );
    // socket?.on(events.STUDENT.name, (data) =>
    //   handleSyncingStatus(data, events.STUDENT)
    // );
    // socket?.on(events.CLASSROOM.name, (data) =>
    //   handleSyncingStatus(data, events.CLASSROOM)
    // );
    // socket?.on(events.SESSION.name, (data) =>
    //   handleSyncingStatus(data, events.SESSION)
    // );
    // socket?.on(events.HOLIDAY.name, (data) =>
    //   handleSyncingStatus(data, events.HOLIDAY)
    // );
    // socket?.on(events.SYNC_ALL, handleSyncingStatus);
    // socket?.on(events.CUSTOM_FIELDS, handleSyncingStatus);
    // socket?.on(events.COMPLETE_SCHEDULE_GENERATION, (data) =>
    //   handleCompleteProcessOfScheduleGeneration(data)
    // );
    // socket?.on(events.SCHEDULE_GENERATION_SYNC.name, (data) =>
    //   handleScheduleGenerationSync(data)
    // );

    // socket.on(events.EXISTING_SESSION_IS_TRANSFER, (data) => {
    //   handleSessionTransfer(data);
    // });

    // socket?.on(events.CIPLEX_CALL.name, handleCiplexCall);

    socket?.on(events.GENERATE_SESSION_BY_AVAILABILITY, (data) => {
      handleData(data);
    });

    socket?.on(events.ENABLE_BOTH_FLOW, (data) => handleEnableBothFlow(data));

    socket?.on(events.UPDATE_LAST_RUN_TIME, (data) =>
      handleUpdateLastSyncTime(data)
    );

    return () => {
      // socket?.off(events.SERVICE.name, handleSyncingStatus);
      // socket?.off(events.SUBJECT.name, handleSyncingStatus);
      // socket?.off(events.TEACHER.name, handleSyncingStatus);
      // socket?.off(events.STUDENT.name, handleSyncingStatus);
      // socket?.off(events.CLASSROOM.name, handleSyncingStatus);
      // socket?.off(events.SESSION.name, handleSyncingStatus);
      // socket?.off(events.HOLIDAY.name, handleSyncingStatus);
      // socket?.off(events.SYNC_ALL, handleSyncingStatus);
      // socket?.off(events.CUSTOM_FIELDS, handleSyncingStatus);
      // socket?.off(
      //   events.COMPLETE_SCHEDULE_GENERATION,
      //   handleCompleteProcessOfScheduleGeneration
      // );
      // socket?.off(
      //   events.SCHEDULE_GENERATION_SYNC.name,
      //   handleScheduleGenerationSync
      // );
      // socket?.off(events.CIPLEX_CALL.name, handleCiplexCall);

      // socket.off(events.EXISTING_SESSION_IS_TRANSFER, handleSessionTransfer);
      socket.off(events.GENERATE_SESSION_BY_AVAILABILITY, handleData);
      socket.off(events.ENABLE_BOTH_FLOW, handleEnableBothFlow);
      socket.off(events.UPDATE_LAST_RUN_TIME, handleUpdateLastSyncTime);
    };
  }, [currentInstitute?.value]);

  const connectSocket = () => {
    const url = REACT_APP_API_URL;
    const socket = openSocket(url as string, {
      forceNew: true,
      transports: ['websocket'],
      query: {
        token,
      },
    });
    return socket;
  };

  return <></>;
};
export default SocketComponent;
