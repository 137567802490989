import { IconInputProps } from '../types/icons';

const SyncIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M13.8987 15.1041C14.0037 15.2096 14.0626 15.3523 14.0626 15.5011C14.0626 15.6498 14.0037 15.7925 13.8987 15.898C13.822 15.9732 12.0108 17.75 9 17.75C6.37102 17.75 4.46273 16.175 3.375 14.948V16.625C3.375 16.7742 3.31574 16.9173 3.21025 17.0227C3.10476 17.1282 2.96168 17.1875 2.8125 17.1875C2.66332 17.1875 2.52024 17.1282 2.41475 17.0227C2.30926 16.9173 2.25 16.7742 2.25 16.625V13.25C2.25 13.1008 2.30926 12.9577 2.41475 12.8523C2.52024 12.7468 2.66332 12.6875 2.8125 12.6875H6.1875C6.33668 12.6875 6.47976 12.7468 6.58525 12.8523C6.69074 12.9577 6.75 13.1008 6.75 13.25C6.75 13.3992 6.69074 13.5423 6.58525 13.6477C6.47976 13.7532 6.33668 13.8125 6.1875 13.8125H3.89812C4.76437 14.8918 6.53906 16.625 9 16.625C11.5312 16.625 13.088 15.1161 13.1034 15.1006C13.2094 14.9957 13.3527 14.9371 13.5018 14.9378C13.6509 14.9384 13.7937 14.9983 13.8987 15.1041ZM15.1875 4.8125C15.0383 4.8125 14.8952 4.87176 14.7898 4.97725C14.6843 5.08274 14.625 5.22582 14.625 5.375V7.05195C13.5373 5.825 11.629 4.25 9 4.25C5.98922 4.25 4.17797 6.0268 4.10203 6.10203C3.9963 6.20739 3.93675 6.35044 3.93648 6.49971C3.93622 6.64898 3.99526 6.79223 4.10063 6.89797C4.20599 7.0037 4.34904 7.06325 4.4983 7.06352C4.64757 7.06378 4.79083 7.00474 4.89656 6.89937C4.91203 6.88391 6.46875 5.375 9 5.375C11.4609 5.375 13.2356 7.1082 14.1019 8.1875H11.8125C11.6633 8.1875 11.5202 8.24676 11.4148 8.35225C11.3093 8.45774 11.25 8.60082 11.25 8.75C11.25 8.89918 11.3093 9.04226 11.4148 9.14775C11.5202 9.25324 11.6633 9.3125 11.8125 9.3125H15.1875C15.3367 9.3125 15.4798 9.25324 15.5852 9.14775C15.6907 9.04226 15.75 8.89918 15.75 8.75V5.375C15.75 5.22582 15.6907 5.08274 15.5852 4.97725C15.4798 4.87176 15.3367 4.8125 15.1875 4.8125Z"
        fill="#D16E1C"
      />
    </svg>
  );
};

export default SyncIcon;
