import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';

export interface ILoader {
  status: boolean;
  isLoading: boolean;
  primaryStep: number;
  progressStatus: number;
  // message: string;
  calendarLoader: boolean;
  replaceData: boolean;
}

const initialState: ILoader = {
  status: false,
  isLoading: false,
  primaryStep: 0,
  progressStatus: 0,
  // message: '',
  calendarLoader: false,
  replaceData: false,
};

const slice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setLoaderState(state: ILoader, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPrimaryStep(state: ILoader, action: PayloadAction<number>) {
      state.primaryStep = action.payload;
    },
    incrementPrimaryStep(state: ILoader) {
      state.primaryStep += 1;
    },
    setProgressStatus(state: ILoader, action: PayloadAction<number>) {
      state.progressStatus = action.payload;
    },
    incrementProgressStatus(state: ILoader) {
      state.progressStatus += 1;
    },
    // setLoaderStatusMessage(state: ILoader, action: PayloadAction<string>) {
    //   state.message = action.payload;
    // },
    setLoaderStatus(state: ILoader, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
    setCalendarLoaderStatus(state: ILoader, action: PayloadAction<boolean>) {
      state.calendarLoader = action.payload;
    },
    setReplaceDataStatus(state: ILoader, action: PayloadAction<boolean>) {
      state.replaceData = action.payload;
    },
  },
});

export const { reducer } = slice;

export const {
  setLoaderState,
  setPrimaryStep,
  incrementPrimaryStep,
  setProgressStatus,
  incrementProgressStatus,
  // setLoaderStatusMessage,
  setLoaderStatus,
  setCalendarLoaderStatus,
  setReplaceDataStatus,
} = slice.actions;

export const getLoaderState = (state: RootStateType) => state.loaderSlice.isLoading;
export const getPrimaryStep = (state: RootStateType) =>
  state.loaderSlice.primaryStep;

export const getProgressStatus = (state: RootStateType) =>
  state.loaderSlice.progressStatus;

// export const getProgressStatusMessage = (state: RootStateType) =>
// state.loaderSlice.message;

export const getLoaderStatus = (state: RootStateType) => state.loaderSlice.status;

export const getReplaceDataStatus = (state: RootStateType) =>
  state.loaderSlice.replaceData;

export const getCalendarLoaderStatus = (state: RootStateType) =>
  state.loaderSlice.calendarLoader;

export const loaderSelector = (state: RootStateType) => state.loaderSlice;

export default slice;
