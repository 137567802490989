import SiteLoader from 'components/Loaders/SiteLoader';
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from 'constants/navigation.constant';
import { useAxiosGet } from 'hooks/useAxios';
import RequiresAuth from 'modules/Auth/components/RequiresAuth';
import ForgotPassword from 'modules/Auth/pages/ForgotPassword';
import ResetPassword from 'modules/Auth/pages/ResetPassword';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { setUserData } from 'reduxStore/slices/authSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';

const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <Suspense fallback={<SiteLoader />}>{route.element}</Suspense>,
  }));
};

const RequiresUnAuth = React.lazy(
  () => import('modules/Auth/components/RequiresUnAuth')
);

const NotFound = React.lazy(() => import('modules/Auth/pages/NotFound'));

const DataValidation = React.lazy(() => import('modules/DataValidation'));
// const ScheduleGeneration = React.lazy(() => import('modules/ScheduleGeneration'));
const Calendar = React.lazy(() => import('modules/Calendar'));

// TODO: Remove these files if not required in future
// const SyncLogs = React.lazy(() => import('modules/Logs/SyncLogs'));
// const ScheduleLogs = React.lazy(() => import('modules/Logs/ScheduleLogs'));

export type RouteObjType = {
  path?: string;
  element: JSX.Element;
  children?: RouteObject[];
  errorElement?: JSX.Element;
};

export const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <RequiresAuth>{route.element}</RequiresAuth>,
  }));
};

const Login = React.lazy(() => import('modules/Auth/pages/Login'));

const Routes = () => {
  const [getRequest] = useAxiosGet();
  const authToken = useSelector(getAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getRequest('/users/logged-in-user');
      dispatch(
        setUserData({
          user: {
            first_name: user.data?.first_name,
            last_name: user.data?.last_name,
            instituteDetails: user.data?.instituteDetails,
          },
        })
      );
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  const AuthenticationRoutes = applySuspense([
    {
      path: PUBLIC_NAVIGATION.login,
      element: <Login />,
    },
    {
      path: PUBLIC_NAVIGATION.forgotPassword,
      element: <ForgotPassword />,
    },
    {
      path: PUBLIC_NAVIGATION.resetPassword,
      element: <ResetPassword />,
    },
  ]);

  const routesForNotAuthenticatedOnly: RouteObject[] = applySuspense([
    {
      element: <RequiresUnAuth />,
      children: AuthenticationRoutes,
    },
  ]);

  const routesForAuthenticatedOnly: RouteObjType[] = applyRequiresAuth([
    // {
    //   path: PRIVATE_NAVIGATION.dashboard.path,
    //   element: <Navigate to={PRIVATE_NAVIGATION.scheduleGeneration.path} replace />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.dataValidation.path,
    //   element: <DataValidation />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.scheduleGeneration.path,
    //   element: <ScheduleGeneration />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.syncLogs.path,
    //   element: <SyncLogs />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.scheduleLogs.path,
    //   element: <ScheduleLogs />,
    // },
    // {
    //   path: PRIVATE_NAVIGATION.calendar.path,
    //   element: <Calendar />,
    // },
    {
      path: PRIVATE_NAVIGATION.home.path,
      element: <DataValidation />,
    },
    {
      path: PRIVATE_NAVIGATION.calendar.existing.path,
      element: <Calendar />,
    },
    {
      path: PRIVATE_NAVIGATION.calendar.optimized.path,
      element: <Calendar />,
    },
  ]);

  const notFound: RouteObject[] = [
    {
      path: '*',
      element: (
        <RequiresAuth>
          <NotFound />
        </RequiresAuth>
      ),
    },
  ];

  const router = createBrowserRouter([
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
    ...notFound,
  ]);
  return <RouterProvider router={router} />;
};

export default Routes;
