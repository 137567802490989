import Header from 'components/Layout/components/Header';
import SocketComponent from 'components/Socket/SocketComponent';
import { useSelector } from 'react-redux';
import {
  currentPageSelector,
  totalPageSelector,
} from 'reduxStore/slices/paginationSlice';

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;

  // Get current page and total pages from Redux store
  const currentPage = useSelector(currentPageSelector);
  const totalPages = useSelector(totalPageSelector);

  // Calculate progress percentage
  const progress = totalPages > 0 ? (currentPage / totalPages) * 100 : 0;

  return (
    <div className="flex h-screen">
      <SocketComponent />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        {totalPages !== 0 && (
          <div className="top-0 left-0 w-full h-1 bg-gray-200 z-50">
            <div
              className="h-1 bg-primaryLight-50 transition-all duration-200"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
        <main className="flex-1 p-0 overflow-auto">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
