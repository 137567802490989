import Button from 'components/Button/Button';
import ReactSelect from 'components/FormElement/ReactSelect';
import { Option } from 'components/FormElement/types';
import BrightmontIcon from 'components/Icon/assets/BrightmontIcon';
import LogoutIcon from 'components/Icon/assets/LogoutIcon';
import SyncIcon from 'components/Icon/assets/SynIcon';
import UserIcon2 from 'components/Icon/assets/UserIcon2';
import Image from 'components/Image';
import Modal from 'components/Modal';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { formatDistanceToNow } from 'date-fns';
import { useAxiosGet } from 'hooks/useAxios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getAuth } from 'reduxStore/slices/authSlice';
import {
  currentInstituteSelector,
  instituteSelector,
  isCurrentProcessFinishedSelector,
  setAvailabilityId,
  setCiplexAvailabilityId,
  setCurrentInstitute,
  setCurrentProcessId,
  setInstitutes,
  setIsCurrentProcessFinished,
  setOptimizerId,
  setProcessId,
  setSyncTime,
  syncTimeSelector,
} from 'reduxStore/slices/instituteSlice';
import {
  getCalendarLoaderStatus,
  getReplaceDataStatus,
  setLoaderState,
} from 'reduxStore/slices/loaderSlice';
import { clearToken } from 'reduxStore/slices/tokenSlice';
import Swal from 'sweetalert2';
import { removeDuplicates } from 'utils';

const navItems = [
  {
    title: PRIVATE_NAVIGATION.home.title,
    path: PRIVATE_NAVIGATION.home.path,
  },
  {
    title: PRIVATE_NAVIGATION.calendar.existing.title,
    path: PRIVATE_NAVIGATION.calendar.existing.path,
  },
  // {
  //   title: PRIVATE_NAVIGATION.calendar.optimized.title,
  //   path: PRIVATE_NAVIGATION.calendar.optimized.path,
  // },
];

export default function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useSelector(getAuth);
  const institutes = useSelector(instituteSelector);
  const currentInstitute = useSelector(currentInstituteSelector);
  const syncTime = useSelector(syncTimeSelector);
  const calendarLoaderStatus = useSelector(getCalendarLoaderStatus);
  const [callApi] = useAxiosGet();
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const updateData = useSelector(getReplaceDataStatus);
  const isCurrentProcessFinished = useSelector(isCurrentProcessFinishedSelector);

  const getOptions = async (
    search: string,
    _loadedOptions: unknown,
    pagination?: { page: number }
  ) => {
    const response = await callApi('/institute', {
      params: {
        page: pagination?.page,
        limit: 10,
        ...(search && { search }),
      },
    });

    const { data } = response;

    let options = data?.responseData?.map((item: { id: number; name: string }) => ({
      value: item.id,
      label: item.name,
    }));

    if (currentInstitute) {
      // Made changes because it give duplicated entry which is already selected please cross check it
      // options = removeDuplicates([currentInstitute, ...options]);
      options = removeDuplicates([...options]);
    }
    if (data?.currentPage === 1) {
      dispatch(setInstitutes(data.responseData));
    } else {
      dispatch(setInstitutes([...institutes, ...data.responseData]));
    }

    if (
      data?.currentPage === 1 &&
      !search &&
      !currentInstitute &&
      options?.length > 0
    ) {
      dispatch(setCurrentInstitute(options[0]));
    }
    return {
      hasMore: data?.currentPage < data?.totalPages,
      options: options ?? [],
      additional: {
        page: !pagination ? 1 : pagination.page + 1,
      },
    };
  };

  const getProcessId = async () => {
    const { data } = await callApi(
      `/schedule-process-logs/last-run-data/${currentInstitute?.value}`
    );

    if (data) {
      if (data?.sync_data?.length > 0) {
        const firstElement = data.sync_data.shift();
        dispatch(setIsCurrentProcessFinished(firstElement.isCompleted));
        if (firstElement.isCompleted) {
          dispatch(setProcessId(firstElement.process_id));
          dispatch(setOptimizerId(firstElement?.optimizer_id ?? ''));
          dispatch(setAvailabilityId(firstElement?.availability_id ?? ''));
          dispatch(
            setCiplexAvailabilityId(firstElement?.ciplexAvailability_id ?? '')
          );

          // dispatch(setLoaderStatus(firstElement.status));
          dispatch(
            setSyncTime(
              formatDistanceToNow(new Date(firstElement.created_at), {
                addSuffix: true,
              })
            )
          );
        } else {
          dispatch(setSyncTime('Syncing Now...'));
          dispatch(setCurrentProcessId(firstElement.process_id));
          const lastRunData = data.sync_data.pop();

          dispatch(setProcessId(lastRunData.process_id));
          dispatch(setOptimizerId(lastRunData?.optimizer_id ?? ''));
          dispatch(setAvailabilityId(lastRunData?.availability_id ?? ''));

          // dispatch(setLoaderStatus(lastRunData.status));
        }

        dispatch(setLoaderState(false));
      } else {
        dispatch(setSyncTime('No data'));
        dispatch(setCurrentProcessId(''));
        dispatch(setIsCurrentProcessFinished(true));
      }
    }
  };

  useEffect(() => {
    if (currentInstitute?.value) {
      dispatch(setLoaderState(true));
      getProcessId();
    }
  }, [currentInstitute?.value]);

  const getUpdateData = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Sync Complete',
      text: 'The synchronization process is now complete. Please press "Continue" to view the latest synced data and ensure you have the most up-to-date information.',
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: 'Yes, do it!',
      reverseButtons: true,
    });
    if (isConfirmed) {
      getProcessId();
    }
  };

  useEffect(() => {
    if (updateData) {
      getUpdateData();
    }
  }, [updateData]);
  return (
    <header className="py-[10px] bg-primaryLight-20 shadow-sm px-[20px]">
      <div className="h-[40px] flex justify-between w-full">
        <Modal modalEl={modalRef} setIsOpen={setIsOpen} />
        <nav className="flex gap-[20px] items-center text-grayText">
          <BrightmontIcon className="w-[113px] h-[40px]" />
          {navItems.map((navItem, index) => (
            <>
              <NavLink
                key={index}
                to={navItem.path}
                className={({ isActive }) =>
                  `text-[16px] font-600  ${isActive ? 'text-primary' : 'text-primaryLight-50 hover:text-primary'}`
                }
              >
                {navItem.title}
              </NavLink>
            </>
          ))}
        </nav>
        <div className="flex items-center space-x-4">
          <div className="flex items-center gap-10px">
            <div>
              <span>Last Sync: </span>
              <span>{syncTime}</span>
            </div>
            <div>
              <SyncIcon
                className={!isCurrentProcessFinished ? 'animate-spin' : ''}
              />
            </div>
          </div>

          <div className="flex items-center">
            <ReactSelect
              disabled={calendarLoaderStatus}
              className="bg-white text-grayText mr-2 rounded-lg text-lg"
              selectedValue={currentInstitute}
              onChange={(option) => {
                dispatch(setCurrentInstitute(option as Option));
              }}
              isPaginated
              loadOptions={getOptions}
              placeholder="Select an institute"
              isClearable={false}
            />
          </div>
          <div className="relative">
            <Button
              className="bg-white rounded-full overflow-hidden text-2xl font-semibold hover:ring-2 hover:ring-primary transition-all duration-200"
              onClickHandler={() => setIsOpen(!isOpen)}
            >
              <Image
                src="/images/profile.jpeg"
                alt="Profile"
                width={40}
                height={40}
              />
            </Button>
            {isOpen && (
              <div
                className="flex flex-col absolute top-full right-0 mt-2 bg-white text-black rounded-md z-[100] shadow-lg border w-48"
                ref={modalRef}
              >
                <div className="px-4 py-2 border-b">
                  <span className="flex items-center gap-2 text-sm font-medium">
                    <UserIcon2 className="w-4 h-4" />
                    {user?.first_name
                      ? `${user.first_name.slice(0, 1).toUpperCase()}${user.first_name.slice(1)} ${user.last_name?.slice(0, 1).toUpperCase()}${user.last_name?.slice(1)}`
                      : 'Profile'}
                  </span>
                </div>
                <Button
                  onClickHandler={() => {
                    dispatch(clearToken());
                  }}
                  className="px-4 py-2 text-left text-sm text-red-500 hover:bg-red-50 transition-colors duration-200"
                >
                  <span className="flex items-center">
                    <LogoutIcon className="w-4 h-4 mr-2" /> Logout
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
