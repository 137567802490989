import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';

type paginationType = {
  currentPage: number;
  totalPages: number;
};

const initialState = {
  currentPage: 1,
  totalPages: 0,
};

export const slice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    currentPageCount: (state: paginationType, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    totalPageCount: (state: paginationType, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
});

export const { reducer } = slice;

export const currentPageSelector = (state: RootStateType) => {
  return state.currentPage.currentPage;
};

export const totalPageSelector = (state: RootStateType) => {
  return state.currentPage.totalPages;
};

export const { currentPageCount, totalPageCount } = slice.actions;

export default slice;
