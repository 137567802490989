export const PUBLIC_NAVIGATION = Object.freeze({
  login: '/auth/login',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/auth/resetPassword/:token',
});

export const PRIVATE_NAVIGATION = Object.freeze({
  // dashboard: {
  //   path: '/',
  //   title: 'Dashboard',
  // },
  // scheduleGeneration: {
  //   path: '/schedule-generation',
  //   title: 'Run Scheduler',
  // },
  // dataValidation: {
  //   path: '/data-validation',
  //   title: 'Data Validation',
  // },
  // syncLogs: {
  //   path: '/sync-logs',
  //   title: 'Sync Logs',
  // },
  // scheduleLogs: {
  //   path: '/schedule-logs',
  //   title: 'Schedule Logs',
  // },
  // calendar: {
  //   path: '/calendar',
  //   title: 'Scheduler Preview',
  // },
  home: {
    path: '/',
    title: 'Home',
  },
  calendar: {
    existing: {
      path: '/calendar/existing',
      title: 'Existing Schedule',
    },
    optimized: {
      path: '/calendar/optimized',
      title: 'Optimized Schedule',
    },
  },
});
