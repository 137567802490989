import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Option } from 'components/FormElement/types';
import { RootStateType } from 'reduxStore/store';

type instituteType = {
  allInstitutes: {
    id: number;
    name: string;
  }[];
  currentInstitute: Option | null;
  processId: string;
  availabilityId: string;
  ciplexAvailabilityId: string;
  optimizerId: string;
  syncTime: string;
  currentProcessId: string;
  isCurrentProcessFinished: boolean;
};

const initialState: instituteType = {
  allInstitutes: [],
  currentInstitute: null,
  processId: '',
  availabilityId: '',
  ciplexAvailabilityId: '',
  optimizerId: '',
  syncTime: '',
  currentProcessId: '',
  isCurrentProcessFinished: false,
};

export const slice = createSlice({
  name: 'institutes',
  initialState,
  reducers: {
    setInstitutes: (
      state: instituteType,
      action: PayloadAction<{ id: number; name: string }[]>
    ) => {
      state.allInstitutes = action.payload;
    },
    setCurrentInstitute: (state: instituteType, action: PayloadAction<Option>) => {
      state.currentInstitute = action.payload;
    },
    setProcessId: (state: instituteType, action: PayloadAction<string>) => {
      state.processId = action.payload;
    },
    setAvailabilityId: (state: instituteType, action: PayloadAction<string>) => {
      state.availabilityId = action.payload;
    },
    setCiplexAvailabilityId: (
      state: instituteType,
      action: PayloadAction<string>
    ) => {
      state.ciplexAvailabilityId = action.payload;
    },
    setOptimizerId: (state: instituteType, action: PayloadAction<string>) => {
      state.optimizerId = action.payload;
    },
    setSyncTime: (state: instituteType, action: PayloadAction<string>) => {
      state.syncTime = action.payload;
    },
    setCurrentProcessId: (state: instituteType, action: PayloadAction<string>) => {
      state.currentProcessId = action.payload;
    },
    setIsCurrentProcessFinished: (
      state: instituteType,
      action: PayloadAction<boolean>
    ) => {
      state.isCurrentProcessFinished = action.payload;
    },
  },
});

export const { reducer } = slice;

export const instituteSelector = (state: RootStateType) => {
  return state.institutes.allInstitutes;
};

export const currentInstituteSelector = (state: RootStateType) => {
  return state.institutes.currentInstitute;
};

export const processIdSelector = (state: RootStateType) => {
  return state.institutes.processId;
};

export const availabilityIdSelector = (state: RootStateType) => {
  return state.institutes.availabilityId;
};

export const optimizerIdSelector = (state: RootStateType) => {
  return state.institutes.optimizerId;
};

export const syncTimeSelector = (state: RootStateType) => {
  return state.institutes.syncTime;
};

export const currentProcessIdSelector = (state: RootStateType) => {
  return state.institutes.currentProcessId;
};

export const isCurrentProcessFinishedSelector = (state: RootStateType) => {
  return state.institutes.isCurrentProcessFinished;
};

export const ciplexAvailabilityIdSelector = (state: RootStateType) => {
  return state.institutes.ciplexAvailabilityId;
};

export const {
  setInstitutes,
  setCurrentInstitute,
  setProcessId,
  setAvailabilityId,
  setOptimizerId,
  setSyncTime,
  setCurrentProcessId,
  setIsCurrentProcessFinished,
  setCiplexAvailabilityId,
} = slice.actions;

export default slice;
